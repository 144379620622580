import { NavLink } from "react-router-dom"
import CornerBrackets from "./CornerBrackets"

export default function SidebarLink({ to, icon, children }) {
    return (
            <NavLink to={to} className="my-1 mx-2 text-stone-400 relative flex items-center">{ 
                ({isActive, isPending}) => (
                    <CornerBrackets>
                        <div className="SidebarLink-brackets w-full flex flex-col content-center">
                            <div className={`SidebarIcon md:absolute w-9 px-1 flex justify-center ${isActive ? "colored-text" : isPending ? "" : ""}`}>
                                {icon}
                            </div>
                            <div className={`SidebarName hidden md:flex flex-col flex-grow justify-center pl-8 pr-2 pt-4 ${isActive ? "colored-text" : isPending ? "" : ""}`}>
                                {children}
                            </div>
                        </div>
                    </CornerBrackets>
                )
            }</NavLink>
    )
}