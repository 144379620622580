import CornerBrackets from "../components/CornerBrackets";
import data from "../data/home.json";

export default function Home() {
    return(
        <div className="text-left pt-4 pb-2">
            <div className="flex">
                <h1 className="text-4xl mb-4 colored-text">{data.name}</h1>
            </div>
            <div className="flex flex-col gap-3">
                <CornerBrackets>
                    <div className="pl-8">
                        {data.profile}
                    </div>
                </CornerBrackets>
                {/* TODO Headshot from data.image */}
                {/* TODO could possibly use Card components for links to socials Github, Linkedin, Leetcode, and hack the box */}
            </div>
        </div>
    )
}