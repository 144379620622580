import Card from "../components/Card";
import data from "../data/projects.json";

export default function Projects() {
    return(
        <div className="text-left pt-4 pb-2">
            <div className="flex">
                <h1 className="text-4xl mb-4 colored-text">Projects</h1>
            </div>
            <div className="flex flex-col gap-3">
                {data.items.map((item, i) => {
                    return (
                        <Card 
                            key={i}
                            id={item.title.toLowerCase().replaceAll(" ", "-")}
                            title={item.title}
                            description={item.summary}
                            dates={item.date}
                            listItems={item.items}
                            link={item.link}
                            />
                    )
                })}
            </div>
        </div>
    )
}