import CornerBrackets from "../components/CornerBrackets"
import Resume from "../components/Resume";

export default function Print() {

    const handlePrintClick = (e) => {
        // Handle request to server alerting click.
        e.preventDefault();
        console.log(`Print click`)
        window.print();
    }

    return(
        <div className="text-left pt-4 pb-2">
            <div className="flex flex-col lg:flex-row justify-between">
                <div className="flex">
                    <h1 className="text-4xl mb-4 colored-text">Print</h1>
                </div>
                <div className="PrintButton lg:justify-normal hidden lg:block">
                    <CornerBrackets>
                        <div className="px-1 cursor-pointer" onClick={(e) => handlePrintClick(e)}>
                            <i className="ri-printer-line text-3xl colored-text"/>
                        </div>
                    </CornerBrackets>
                </div>
                <div className="PrintButton flex justify-center lg:justify-normal lg:hidden">
                    <div className="px-1 cursor-pointer" onClick={(e) => handlePrintClick(e)}>
                        <i className="ri-printer-line text-[16rem] lg:text-3xl colored-text"/>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <Resume/>
            </div>
        </div>
    )
}