import "../styles/Resume.css";
import homeData from "../data/home.json";
import awardsData from "../data/awards.json";
import experienceData from "../data/experience.json";
import educationData from "../data/education.json";
import projectsData from "../data/projects.json";
import skillsData from "../data/skills.json";
import dateToString from "../functions/dateToString";


export default function Resume() {

    // TODO overhaul to make this hard to overflow
    return(
        <div id="Resume" className="Resume hidden print:block lg:flex w-[8.5in] bg-white text-black flex-col shadow-2xl box-border p-4 overflow-hidden">
            {/* Header */}
            <div className="flex pb-2 border-b-2 border-black">
                {/* Name and title */}
                <div className="flex flex-col grow border-r-2 border-black">
                    <div className="Name text-5xl">
                        {homeData.name}
                    </div>
                    <div className="Title text-2xl pl-2">
                        {homeData.title}
                    </div>
                </div>

                {/* Email and Phone */}
                <div className="flex flex-col justify-between pl-2">
                    <div className="Website"><i className="ri-pages-line"></i> <u>donnis.net</u></div>
                    <div className="Email"><i className="ri-mail-line"></i> donnis@donnis.net</div>
                    {/* <div className="Phone"><i className="ri-phone-line"></i> </div>  */}
                </div>
            </div>
            {/* TODO combine this with the line above to save space */}
            <div className="Profile pb-2 border-b-2 border-black">
                <div className="ProfileTitle text-3xl">{"Profile".toUpperCase()}</div>
                <div className="ProfileText text-sm pl-2">
                    {homeData.shortProfile}
                </div>
            </div>

            <div className="grow flex pt-2">

                {/* Left Column */}
                <div className="LeftColumn w-[34%] border-r-2 border-black">

                    {/* Education */}
                    <div className="Education pb-2 mr-2 border-b-2 border-black">
                        <div className="EducationTitle text-3xl">
                            {educationData.catagory.toUpperCase()}
                        </div>
                        <div className="EducationItems">
                            {educationData.items.map((item, i) => {
                                return (
                                    <div key={i} className="pt-2">
                                        <div className="Degree text-md font-bold">
                                            {item.degree}
                                        </div>
                                        <div className="School pl-2 text-sm">
                                            {item.school}
                                        </div>
                                        <div className="Graduation pl-2 text-xs text-stone-600">
                                            {dateToString(new Date(item.graduation))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {/* Awards */}
                    <div className="Awards pb-2 mr-2 border-b-2 border-black">
                        <div className="AwardsTitle text-3xl">
                            {awardsData.catagory.toUpperCase()}
                        </div>
                        <div className="AwardsItems grid grid-cols-2">
                            {awardsData.items.map((item, i) => {
                                return (
                                    <div key={i} className="pt-2">
                                        <div className="AwardTitle text-md font-bold">
                                            {item.title}
                                        </div>
                                        <div className="Issuer pl-2 text-sm">
                                            {item.issuer}
                                        </div>
                                        <div className="Recieved pl-2 text-xs text-stone-600">
                                            {dateToString(new Date(item.date))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {/* Skills */}
                    <div className="Skills">
                        <div className="SkillsTitle text-3xl">
                            SKILLS
                        </div>
                        <div className="SkillsItems flex flex-wrap justify-start gap-3 text-xs">
                            {
                                skillsData.items.map((skill, i) => {
                                    return <div key={i} className="Skill overflow-hidden whitespace-nowrap"><i>{skill}</i></div>
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* Right Column */}
                <div className="RightColumn pl-2 w-[66%]">

                    {/* Experience */}
                    <div className="Experience border-b-2 border-black">
                        <div className="ExperienceTitle text-3xl">
                            {experienceData.catagory.toUpperCase()}
                        </div>
                        <div className="ExperienceItems">
                            {experienceData.items.map((item, i) => {
                                return (
                                    <div key={i} className="pt-2">
                                        <div className="ExperienceTitle text-md font-bold">
                                            {item.title} <i className="ri-circle-fill ri-xxs align-middle"></i> {item.company}
                                        </div>
                                        <div className="ExperienceRanges flex gap-2 pl-2 text-xs text-stone-600">
                                            {item.timeRanges.map((item, i) => {
                                                return (
                                                    <div key={i}>{dateToString(new Date(item.from))} <i className="ri-arrow-right-s-line"></i> {dateToString(new Date(item.to))}</div>
                                                )
                                            })}
                                        </div>
                                        <ul className="list-disc pl-4 text-xs">
                                            {item.items.map((listItem, i) => {
                                                return (
                                                    <li key={i} className="">{listItem}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {/* Projects */}
                    <div className="Projects">
                        <div className="ProjectTitle text-3xl">
                            {projectsData.catagory.toUpperCase()}
                        </div>
                        {projectsData.items.map((item, i) => {
                            return (
                                <div key={i} className="pt-2">
                                    <div className="AwardTitle text-md font-bold">
                                        {item.title}
                                    </div>
                                    <div className="Recieved flex gap-2 pl-2 text-xs text-stone-600">
                                        {dateToString(new Date(item.date))}
                                    </div>
                                    <ul className="list-disc pl-4 text-xs">
                                        {item.items.map((listItem, i) => {
                                            return (
                                                <li key={i} className="">{listItem}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}