import { Outlet } from "react-router-dom";
import SidebarLink from "../components/SidebarLink";


export default function Layout() {
    return(
        <div className="min-h-screen flex">
            <div className="Logo logo-font z-30 fixed bottom-0 right-0 text-3xl bg-black bg-opacity-90 rounded-tl-lg lg:top-0 lg:bottom-auto lg:rounded-tl-none lg:rounded-bl-lg lg:text-3xl">
                <div className="Logo-text pl-4 pr-2">
                    <b>donnis.net</b>
                </div>
            </div>
            
            {/* Normal Nav Bar */}
            <div className="flex fixed z-30 top-0 left-0 bg-black bg-opacity-90 w-full justify-center lg:justify-normal lg:rounded-br-lg h-16 lg:max-w-[80%] lg:w-auto">
                <SidebarLink to="/" icon={(<i className="ri-user-smile-line" style={{"fontSize": "24px"}}></i>)}>Home</SidebarLink>
                <SidebarLink to="/experience" icon={(<i className="ri-briefcase-3-line" style={{"fontSize": "24px"}}></i>)}>Experience</SidebarLink>
                <SidebarLink to="/education" icon={(<i className="ri-graduation-cap-line" style={{"fontSize": "24px"}}></i>)}>Education</SidebarLink>
                <SidebarLink to="/projects" icon={(<i className="ri-code-s-slash-line" style={{"fontSize": "24px"}}></i>)}>Projects</SidebarLink>
                <SidebarLink to="/awards+certs" icon={(<i className="ri-award-line" style={{"fontSize": "24px"}}></i>)}>Awards</SidebarLink>
                <SidebarLink to="/print" icon={(<i className="ri-printer-line" style={{"fontSize": "24px"}}></i>)}>Print</SidebarLink>
                {/* <SidebarLink to="/game" icon={(<i className="ri-gamepad-line" style={{"fontSize": "24px"}}></i>)}>Game</SidebarLink> */}
            </div>

            <div className="Content grow">
                <div className="Content-backgroud bg-stone-800 h-full mx-0.5 bg-opacity-95 flex flex-row justify-center">
                    {/* <div className="Content-grid flex-grow-[.5]"></div> */}
                    <div className="Content-island flex-grow max-w-[72rem] pt-16 pb-9 text-stone-200 px-2">
                        <Outlet/>
                    </div>
                    {/* <div className="Content-grid flex-grow-[.5]"></div> */}
                </div>
            </div>
        </div>
    )
}