import Card from "../components/Card";
import data from "../data/education.json";

export default function Education() {
    return(
        <div className="text-left pt-4 pb-2">
            <div className="flex">
                <h1 className="text-4xl mb-4 colored-text">Education</h1>
            </div>
            <div className="flex flex-col gap-3">
                {data.items.map((item, i) => {
                    return (
                        <Card 
                            key={i}
                            id={(item.school + " " + item.degree).toLowerCase().replaceAll(" ", "-")}
                            title={item.degree}
                            subtitle={item.school}
                            dates={item.graduation}
                            image={item.link}
                            link={item.link}
                            />
                    )
                })}
            </div>
        </div>
    )
}