import { useState } from "react";
import dateToString from "../functions/dateToString";
import CornerBrackets from "./CornerBrackets";
import ImageModal from "./ImageModal";

export default function Card({ id, title, dates, subtitle, description, link, image, icon, listItems}) {
    const [imageShown, setImageShown] = useState(false);

    const handleLinkClick = (e) => {
        // Handle request to server alerting click.
        e.preventDefault();
        console.log(`link clicked: ${link}`)
        window.open(link, "_blank")
    }

    const handleImageClick = (e) => {
        // Handle request to server alerting click.
        e.preventDefault();
        console.log(`image clicked: ${image}`)
        setImageShown((prev) => !prev);
    }

    return (
        // Card
        <div id={id} className={`Card bg-black bg-opacity-10 flex flex-col md:flex-row justify-between rounded-3xl grow`}>
            {/* Text portion of card */}
            <div className="Text p-4 grow flex">
                <div className="grow">

                    {/* Title */}
                    <div className="Title text-md flex md:text-2xl">
                        {title} 
                    </div>

                    {/* Subtitle */}
                    {subtitle ?
                        <div className="Subtitle text-sm md:text-xl pl-4">
                            {subtitle}
                        </div>
                    : null}
                    {/* Dates */}
                    <div className="">
                        {dates ?
                        Array.isArray(dates) ?
                        // Array of dates
                            <div className="flex gap-2">
                                {dates.map((item, i) => {
                                    return (
                                        <h3 key={i} className="flex text-stone-400 text-xs md:text-sm">{dateToString(new Date(item.from))} <i className="ri-arrow-right-s-line"></i> {dateToString(new Date(item.to))}</h3>
                                    )
                                })}
                            </div>
                        : // Single date
                            <div className="">
                                <h3 className="flex text-stone-400 text-xs md:text-sm">{dateToString(new Date(dates))}</h3>
                            </div>
                        : null}
                    </div>

                    {/* Description */}
                    {description ? 
                        <div className="flex justify-center pb-4 text-md md:text-xl italic">
                            {description}
                        </div>
                    : null}
                    
                    {/* List Items */}
                    <div className="List">
                        {listItems ? 
                            <ul className="ml-[22px] text-sm md:text-lg list-disc">
                                {listItems.map((item, i) => {
                                    return (<li key={i}>{item}</li>)
                                })}
                            </ul>
                        : null}
                    </div>
                </div>
                
                {/* Card buttons */}
                <div className="flex flex-col justify-between gap-4">
                    {/* Link button */}
                    {/* Update this to use the same style as the nav links */}
                    <div className="">
                        {link ? 
                            <CornerBrackets>
                                <a href={link} className="Link pt-2 pl-2 pb-1.5 pr-1.5" onClick={(e) => handleLinkClick(e)}>
                                    <i className="ri-external-link-line text-lg w-8 h-8"/>
                                </a>
                            </CornerBrackets>
                        : null}
                    </div>
                    
                    {/* Image button for mobile
                    <div className="md:hidden">
                        {image ?
                            <CornerBrackets>
                                <a href={image} className="Link pt-2 pl-2 pb-1.5 pr-1.5" onClick={(e) => handleImageClick(e)}>
                                    <i className="ri-image-line text-lg w-8 h-8"/>
                                </a>
                                <ImageModal shown={imageShown}/>
                            </CornerBrackets>
                        : null}
                    </div> */}
                </div>
            </div>
            {/* Image or Icon portion of card if image is there */}
            <div className="Image">
                {image ?
                    <div className="flex flex-row-reverse">
                        {/* TODO: add loader to alt */}
                        <img src={image} className="hidden md:flex md:w-60 md:rounded-r-3xl md:h-auto" alt="Card"></img>
                    </div>
                : icon ?
                    <div className="flex flex-row-reverse">
                        <i className={`${icon} text-[8rem] w-32 aspect-square grow`}></i>
                    </div>
                : null}
            </div>
        </div>
    )
}