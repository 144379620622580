const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

export default function dateToString(date) {
    if(isNaN(date)) {
        return "Present";
    }
    const month = monthNames[date.getMonth()];

    const year = date.getFullYear();

    return `${month} ${year}`;
}