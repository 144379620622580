import Card from "../components/Card"
import data from "../data/awards.json"

export default function Awards() {
    return(
        <div className="text-left pt-4 pb-2">
            <div className="flex">
                <h1 className="text-4xl mb-4 colored-text">Awards</h1>
            </div>
            <div className="flex flex-col gap-3">
                {data.items.map((item, i) => {
                    return (
                        <Card
                            key={i}
                            id={(item.issuer + " " + item.title).toLowerCase().replaceAll(" ", "-")}
                            title={item.title}
                            subtitle={item.issuer}
                            dates={item.date}
                            link={item.link}
                            image={item.image}
                            />
                    )
                })}
            </div>
        </div>
    )
}