import Card from "../components/Card";
import data from "../data/experience.json";

export default function Experience() {
    return(
        <div className="text-left pt-4 pb-2">
            <div className="flex">
                <h1 className="text-4xl mb-4 colored-text">Experience</h1>
            </div>
            <div className="flex flex-col gap-3">
                {data.items.map((item, i) => {
                    return (
                        <Card
                            key={i}
                            id={(item.company + " " + item.title).toLowerCase().replaceAll(" ", "-")}
                            title={item.title}
                            subtitle={item.company}
                            dates={item.timeRanges}
                            listItems={item.items}
                            />
                    )
                })}
            </div>
        </div>
    )
}