import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import 'remixicon/fonts/remixicon.css';
import reportWebVitals from './reportWebVitals';

import Layout from './routes/Layout';
import Home from './routes/Home';
import Experience from './routes/Experience';
import Education from './routes/Education';
import Projects from './routes/Projects';
import Awards from './routes/Awards';
import Print from './routes/Print';
import Game from './routes/Game';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/experience",
        element: <Experience />,
      },
      {
        path: "/education",
        element: <Education />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/awards+certs",
        element: <Awards />,
      },
      {
        path: "/print",
        element: <Print />,
      },
      {
        path: "/game",
        element: <Game />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
